import { Icon } from '@chakra-ui/react'

export const github = (props: any) => (
  <Icon viewBox='0 0 25 25' {...props}>
    <path
      fill='currentColor'
      d='M12 0C5.374 0 0 5.373 0 12c0 5.302 3.438 9.8 8.207 11.387.599.111.793-.261.793-.577v-2.234c-3.338.726-4.033-1.416-4.033-1.416-.546-1.387-1.333-1.756-1.333-1.756-1.089-.745.083-.729.083-.729 1.205.084 1.839 1.237 1.839 1.237 1.07 1.834 2.807 1.304 3.492.997.107-.775.418-1.305.762-1.604-2.665-.305-5.467-1.334-5.467-5.931 0-1.311.469-2.381 1.236-3.221-.124-.303-.535-1.524.117-3.176 0 0 1.008-.322 3.301 1.23A11.509 11.509 0 0 1 12 5.803c1.02.005 2.047.138 3.006.404 2.291-1.552 3.297-1.23 3.297-1.23.653 1.653.242 2.874.118 3.176.77.84 1.235 1.911 1.235 3.221 0 4.609-2.807 5.624-5.479 5.921.43.372.823 1.102.823 2.222v3.293c0 .319.192.694.801.576C20.566 21.797 24 17.3 24 12c0-6.627-5.373-12-12-12z'
    />
  </Icon>
)

export const linkedin = (props: any) => (
  <Icon viewBox='0 0 25 25' {...props}>
    <path
      fill='currentColor'
      d='M19 0h-14c-2.761 0-5 2.239-5 5v14c0 2.761 2.239 5 5 5h14c2.762 0 5-2.239 5-5v-14c0-2.761-2.238-5-5-5zm-11 19h-3v-11h3v11zm-1.5-12.268c-.966 0-1.75-.79-1.75-1.764s.784-1.764 1.75-1.764 1.75.79 1.75 1.764-.783 1.764-1.75 1.764zm13.5 12.268h-3v-5.604c0-3.368-4-3.113-4 0v5.604h-3v-11h3v1.765c1.396-2.586 7-2.777 7 2.476v6.759z'
    />
  </Icon>
)

export const moon = (props: any) => (
  <Icon viewBox='0 0 1000 1000' {...props}>
    <path
      fill='currentColor'
      d='M525.3,989.5C241.2,989.5,10,758.3,10,474.1c0-196.8,109.6-373.6,285.9-461.4c7.9-3.9,17.5-2.4,23.7,3.8c6.2,6.2,7.9,15.8,4,23.7c-32.2,65.4-48.5,135.7-48.5,208.9c0,261.4,212.7,474.1,474.1,474.1c74,0,145-16.7,211-49.5c7.9-3.9,17.5-2.4,23.7,3.8c6.3,6.3,7.9,15.8,3.9,23.7C900.5,879,723.3,989.5,525.3,989.5z'
    />
  </Icon>
)

export const glasses = (props: any) => (
  <Icon viewBox='0 0 25 25' {...props}>
    <path
      fill='currentColor'
      d='M24 12c0 .552-.448 1-1 1l-.099-.02c-.456 2.292-2.475 4.02-4.901 4.02-2.082 0-3.85-1.277-4.596-3.088-.441-1.071-.326-2.447-1.429-2.447-1.053 0-.897 1.303-1.392 2.481-.754 1.793-2.514 3.054-4.583 3.054-2.425 0-4.445-1.728-4.901-4.02l-.099.02c-.552 0-1-.448-1-1s.448-1 1-1h.101c.463-2.282 2.481-4 4.899-4 2.357 0 4.319 1.636 4.848 3.83.343-.243.743-.366 1.126-.366.39 0 .809.131 1.169.393.519-2.209 2.491-3.857 4.857-3.857 2.419 0 4.436 1.718 4.899 4h.101c.552 0 1 .448 1 1zm-14 0c0-2.206-1.794-4-4-4s-4 1.794-4 4 1.794 4 4 4 4-1.794 4-4zm12 0c0-2.206-1.794-4-4-4s-4 1.794-4 4 1.794 4 4 4 4-1.794 4-4zm-5-2.938c.198-.037.401-.062.609-.062 1.872 0 3.391 1.518 3.391 3.391l-.028.283c-.762-1.693-2.201-3.01-3.972-3.612zm-13.972 3.611l-.028-.282c0-1.873 1.519-3.391 3.391-3.391.208 0 .411.025.609.062-1.771.602-3.21 1.919-3.972 3.611z'
    />
  </Icon>
)

export const cat = (props: any) => (
  <Icon viewBox='0 0 25 25' {...props}>
    <path
      fill='currentColor'
      d='M8.5 11c0 .552-.449 1-1 1-.551 0-1-.448-1-1s.449-1 1-1c.551 0 1 .448 1 1zm9 0c0 .552-.448 1-1 1s-1-.448-1-1 .448-1 1-1 1 .448 1 1zm3.639 4.526l-3.471 1c-.635.187-.913-.778-.277-.961l3.47-1c.637-.185.911.779.278.961zm-1.477 3.446l-2.284-.728c-.63-.2-.33-1.148.303-.953l2.284.728c.637.203.316 1.147-.303.953zm-5.162-7.972c0-1.104.896-2 2-2s2 .896 2 2-.896 2-2 2-2-.896-2-2zm.956 7.351c-.548 1.214-2.471 1.831-3.456.543-.987 1.289-2.91.67-3.456-.543-.271-.601.639-1.015.912-.411.336.747 2.033 1.302 2.044-.796v-.505c-.615-.218-1.062-.798-1.062-1.313 0-.646.7-.935 1.562-.935.861 0 1.562.289 1.562.935 0 .515-.447 1.094-1.062 1.313v.505c.009 2.119 1.713 1.532 2.044.796.27-.602 1.184-.192.912.411zm-9.956-7.351c0-1.104.896-2 2-2s2 .896 2 2-.896 2-2 2-2-.896-2-2zm1.123 7.244l-2.285.728c-.625.192-.937-.751-.304-.953l2.285-.728c.636-.197.93.753.304.953zm-3.484-3.679l3.47 1c.638.184.356 1.148-.277.961l-3.471-1c-.638-.183-.354-1.147.278-.961zm15.589-14.565c-2.082 1.814-3.082 3.044-4.546 5.261-1.213-.298-3.206-.293-4.364 0-1.401-2.11-2.405-3.344-4.546-5.261-3.068 4.042-5.272 8.939-5.272 13.565 0 5.759 3.397 10.435 12 10.435s12-4.676 12-10.435c0-4.578-2.207-9.502-5.272-13.565z'
    />
  </Icon>
)